import isLength from 'validator/es/lib/isLength'
import mime from 'mime-types'
import { getUserInfo } from './auth.js'
import i18n from '@/i18n/'
import router from '@/router'

// this.$t()
const getStr = function(key) {
  // 判断国外
  const abroad = router.app._route.query.abroad
  const values = []
  let len = arguments.length - 1
  let locale = i18n.locale
  if (abroad === 'abroad') locale = 'en'
  while (len-- > 0) values[ len ] = arguments[ len + 1 ]
  // return i18n.t.apply(key)
  return i18n._t.apply(i18n, [key, locale, i18n._getMessages(), this].concat(values))
}

// ==================================================
// 是否国内
// ==================================================
export function isDomestic() {
  const country = getUserInfo().country
  return country === 'CHINA'
}

// ==================================================
// 文档类型限制与检测
// ==================================================
export const docMemeType = 'application/msexcel,application/msword,application/pdf,image/jpeg,image/png,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsx,.xls,.doc,.docx'
export function isDoc(type) {
  return ['png', 'jpg', 'jpeg', 'pdf', 'doc', 'docx', 'xlsx', 'xls'].includes(mime.extension(type))
}

// ==================================================
// 图片类型限制与检测
// ==================================================
export const imageMimeType = 'image/jpeg,image/png'
export function isImage(type) {
  return ['png', 'jpeg'].includes(mime.extension(type))
}

// ==================================================
// form item check
// ==================================================
export default {
  isRequired(key) {
    return (rule, value, callback) => {
      if (!value) {
        callback(new Error(getStr(key)))
      }
      callback()
    }
  },
  notEmpty({ message, min, max }) {
    return (rule, value, callback) => {
      if (value.length > max || value.length < min) {
        callback(new Error(message))
      } else if (!/^[\u4e00-\u9fa5\w. ]*$/.test(value)) {
        callback(new Error(message))
      }
      callback()
    }
  },
  description(rule, value, callback) {
    if (!isLength(value, { min: 1, max: 200 })) {
      callback(new Error(getStr('请输入200字以内律师简介')))
    }
    callback()
  },
  idNumber(key = 'c6cc2f9') {
    return (rule, value, callback) => {
      // const regexp_zh = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
      // const regexp_other = /^[\w][\w]{4, 32}$/
      // const regexp = isDomestic ? regexp_zh : regexp_other
      // const regexp = isDomestic ? regexp_other : regexp_other
      const regexp = /^[0-9a-zA-Z]([a-zA-Z0-9]{3,31})$/
      if (!regexp.test(value)) {
        callback(new Error(getStr(key)))
      }
      callback()
    }
  },
  lawyerIdCard(rule, value, callback) {
    const regexp = /^[0-9a-zA-Z]([a-zA-Z0-9]{3,31})$/
    if (!regexp.test(value)) {
      callback(new Error(getStr('a5bb87b')))
    }
    callback()
  },
  licenseNumber(rule, value, callback) {
    // const reg = /^[\w][\w]{4, 32}$/
    const reg = /^[_a-zA-Z0-9]([_a-zA-Z0-9]{3,31})$/
    if (!reg.test(value)) {
      callback(new Error(getStr('4efad5c')))
    }
    callback()
  },
  name(rule, value, callback) {
    if (!/^[\u4e00-\u9fa5\w.]{2,32}$/.test(value)) {
      callback(new Error(getStr('f8c2a08')))
    }
    callback()
  },
  lawyerName(rule, value, callback) {
    if (!/^[\u4e00-\u9fa5\w.]{2,32}$/.test(value)) {
      callback(new Error(getStr('8c6326a')))
    }
    callback()
  },
  compayName(rule, value, callback) {
    if (!/^[\u4e00-\u9fa5\w.][\u4e00-\u9fa5\w.]{5,299}$/.test(value)) {
      callback(new Error(getStr('cc4e181')))
    }
    callback()
  },
  organizationName(rule, value, callback) {
    if (!/^[\u4e00-\u9fa5\w._()（）\-][\u4e00-\u9fa5\w._()（）\-]{3,149}$/.test(value)) {
      callback(new Error(getStr('f7195fa')))
    }
    callback()
  },
  legalPerson(rule, value, callback) {
    if (!/^[\u4e00-\u9fa5\w.][\u4e00-\u9fa5\w.]{1,31}$/.test(value)) {
      callback(new Error(getStr('94d558b')))
    }
    callback()
  },
  address(rule, value, callback) {
    if (!isLength(value, { min: 6, max: 300 }) || !/^[\u4e00-\u9fa5\w._\- ]*$/.test(value)) {
      callback(new Error(getStr('3f0b254')))
    }
    callback()
  },
  enterpriseAddress(rule, value, callback) {
    if (!isLength(value, { min: 6, max: 300 }) || !/^[\u4e00-\u9fa5\w._\- ]*$/.test(value)) {
      callback(new Error(getStr('1be67d0')))
    }
    callback()
  },
  organizationAddress(rule, value, callback) {
    if (!isLength(value, { min: 6, max: 300 }) || !/^[\u4e00-\u9fa5\w._\- ]*$/.test(value)) {
      callback(new Error(getStr('d9ad3ee')))
    }
    callback()
  },
  short(rule, value, callback) {
    const reg = /^[0-9]([0-9]{5})$/
    const abroad = router.app._route.query.abroad
    let key1 = '41dad45'
    let key2 = 'aa03b28'
    if (abroad === 'abroad') {
      key1 = '3c12dca'
      key2 = '3c12dca'
    }
    if (!value) {
      callback(new Error(getStr(key1)))
    }
    if (!reg.test(value)) {
      callback(new Error(getStr(key2)))
    }
    callback()
  },
  email(rule, value, callback) {
    if (!isLength(value, { max: 92 }) || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      callback(new Error(getStr('9ccd5ab')))
    }
    callback()
  },
  // account(rule, value, callback) {
  //   if (!/^1[\d]{10}$/.test(value)) {
  //     callback(new Error('请输入用户名/手机号/电子邮箱'))
  //   }
  //   callback()
  // },
  phone_zh(rule, value, callback) {
    if (!/^1[\d]{10}$/.test(value)) {
      callback(new Error(getStr('d081c4c')))
    }
    callback()
  },
  phone_other(rule, value, callback) {
    if (!isLength(value, { min: 13, max: 13 })) {
      callback(new Error(getStr('d081c4c')))
    }
    callback()
  },
  contactNumber(rule, value, callback) {
    if (!/^\d{11,11}$/.test(value)) {
      callback(new Error(getStr('89a6994')))
    }
    callback()
  },
  creditCode_zh(rule, value, callback) {
    if (!isLength(value, { min: 6, max: 100 })) {
      callback(new Error(getStr('5281eb9')))
    }
    // if (!/(^\S{15}$)|(^\S{18}$)|(^\S{20}$)/.test(value)) {
    //   callback(new Error('请输入正确的统一社会信用代码'))
    // }
    callback()
  },
  creditCode_other(rule, value, callback) {
    if (!/(^\S{6}$)|(^\S{18}$)|(^\S{20}$)/.test(value)) {
      callback(new Error(getStr('5281eb9')))
    }
    callback()
  },
  graphical(rule, value, callback) {
    const reg = /^[0-9a-zA-Z]+$/
    if (!reg.test(value)) {
      callback(new Error(getStr('abe0383')))
    }
    callback()
  },
  userName(rule, value, callback) {
    const reg = /^[a-zA-Z]([_a-zA-Z0-9]{4,19})$/
    if (!reg.test(value)) {
      callback(new Error(getStr('84a89ce')))
    }
    callback()
  },
  password(rule, value, callback) {
    const reg = /(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^[^\s\u4e00-\u9fa5]{8,20}$/
    if (!reg.test(value)) {
      callback(new Error(getStr('15f282c')))
    }
    callback()
  },
}
